import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { appSaga } from './common';
import { carFreeZonesSaga } from './common/store/car-free-zones/carFreeZones.saga';
import { moovSaga } from './common/store/moov/moov.saga';
import { paymentsSaga } from './common/store/payments/payments.saga';
import { servicesSaga } from './common/store/services/Services.saga';
import { userSaga } from './common/store/user/user.saga';
import { workOrderItemsSaga } from './common/store/work-order-item/work-order-items.saga';
import { cpaSaga } from './cpa';
import { notificationsSaga } from './notification/store/notification.saga';
import { planningSaga } from './planning';
import { eventsSaga, modulesSaga, signsSaga } from './signs';
import { reorderingSaga } from './tour-reordering/store/reordering.saga';
import {
  appFeaturesSaga,
  metadataSaga,
  newsflashSaga,
  requestLocationsSaga,
  requestsSaga,
  sgwAdvisingPartiesSaga,
  sgwNatureOfWorksSaga,
  sgwPartnersSaga,
  sgwPermitConditionsSaga,
  sgwPhasesSaga,
  sgwProjectsSaga,
  sgwRequestRescheduling,
  sgwRequestsSaga,
  sgwSettingsSaga,
  sgwTrafficMeasuresSaga,
  snackbarSaga,
  storageSaga,
  streetmapperSaga,
  utilityCompaniesSaga,
} from './store/sagas';

export function* rootSaga(): SagaIterator {
  yield all([
    fork(appSaga),
    fork(carFreeZonesSaga),
    fork(cpaSaga),
    fork(eventsSaga),
    fork(metadataSaga),
    fork(modulesSaga),
    fork(moovSaga),
    fork(notificationsSaga),
    fork(paymentsSaga),
    fork(planningSaga),
    fork(reorderingSaga),
    fork(requestLocationsSaga),
    fork(requestsSaga),
    fork(servicesSaga),
    fork(sgwAdvisingPartiesSaga),
    fork(sgwNatureOfWorksSaga),
    fork(sgwPartnersSaga),
    fork(sgwPermitConditionsSaga),
    fork(sgwPhasesSaga),
    fork(sgwProjectsSaga),
    fork(sgwRequestRescheduling),
    fork(sgwRequestsSaga),
    fork(sgwSettingsSaga),
    fork(sgwTrafficMeasuresSaga),
    fork(signsSaga),
    fork(storageSaga),
    fork(snackbarSaga),
    fork(userSaga),
    fork(utilityCompaniesSaga),
    fork(workOrderItemsSaga),
    fork(appFeaturesSaga),
    fork(newsflashSaga),
    fork(streetmapperSaga),
  ]);
}
