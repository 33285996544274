import React, { FC } from 'react';
import { Auth } from '../../../../atoms';
import { IStreetmapperAclActions } from '../../../../../types';
import { Header } from '../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../common/config/url.constants';
import { translate } from '../../../../../common/translations/translate';
import { Container } from '../../../../../common';
import { useFormStyles } from '../../../../../style/form.styles';
import { NewsflashForm } from '../../../../forms/NewsflashForm/NewsflashForm.component';
import { StreetmapperForm } from '../../../../forms/StreetmapperForm/StreetmapperForm.component';

export const EditStreetmapperPage: FC = () => (
  <Auth acl={IStreetmapperAclActions.editStreetMapper} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.settings.streetmapper.base}>{translate('settings.streetmapper.title')}</Header>
    <Container className={useFormStyles().container}>
      <StreetmapperForm title={translate('settings.streetmapper.details')} />
    </Container>
  </Auth>
);
