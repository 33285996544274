import {
  INewsflash,
  ISgwPartner,
  ISgwPermitCondition,
  ISgwProject,
  ISgwTrafficMeasure,
  IStreetmapper,
} from '../../types';
import { generateBasicRestApi } from '../../utils';

export * from './appFeatures.api';
export * from './geolocation.api';
export * from './requests.api';
export * from './sgwNatureOfWorks.api';
export * from './sgwSettings.api';
export * from './storage.api';
export * from './utilityCompanies.api';

export const SgwPartnersApi = generateBasicRestApi<ISgwPartner>('/v1/sgw/partners');
export const SgwPermitConditionsApi = generateBasicRestApi<ISgwPermitCondition>('/v1/sgw/permit_conditions');
export const SgwProjectsApi = generateBasicRestApi<ISgwProject>('/v1/sgw/projects');
export const SgwTrafficMeasuresApi = generateBasicRestApi<ISgwTrafficMeasure>('/v1/sgw/trafficmeasures');
export const NewsflashApi = generateBasicRestApi<INewsflash>('/v1/newsflashes');
export const StreetmapperApi = generateBasicRestApi<IStreetmapper>('/v1/street_mappers');
