import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect } from '../../types';
import { StreetmapperActions } from '../actions';
import { StreetmapperApi } from '../api';
import { getPagedApiParams } from '../selectors/streetmapper.selectors';
import { push } from 'connected-react-router';
import { appUrls } from '../../common/config/url.constants';

function* onFetchStreetmapperList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof StreetmapperApi.fetchList> = yield call(StreetmapperApi.fetchList, params!);
  yield all([
    put(
      StreetmapperActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(StreetmapperActions.list.set(response!.data.data)),
  ]);
}

function* onFetch({ payload }: ReturnType<typeof StreetmapperActions.fetch>): SagaIterator {
  const response: ICall<typeof StreetmapperApi.fetch> = yield call(StreetmapperApi.fetch, payload);
  yield put(StreetmapperActions.list.set([response!.data.data]));
}

function* onSave({ payload }: ReturnType<typeof StreetmapperActions.save>): SagaIterator {
  const response: ICall<typeof StreetmapperApi.fetch> = yield call(StreetmapperApi.save, payload);
  yield put(StreetmapperActions.list.set([response!.data.data]));
  yield put(push(appUrls.settings.streetmapper.detail(`${response!.data.data.id}`)));
}

export function* streetmapperSaga(): SagaIterator {
  yield takeLatest(StreetmapperActions.list.fetch.type, genericErrorHandler(onFetchStreetmapperList));
  yield takeLatest(StreetmapperActions.fetch.type, genericErrorHandler(onFetch));
  yield takeLatest(StreetmapperActions.save.type, genericErrorHandler(onSave));
}
