import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import {
  CreateStreetmapperPage,
  DetailStreetmapperPage,
  EditStreetmapperPage,
  StreetmapperPage,
} from '../pages/Settings/Streetmapper';

export const StreetmapperRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.settings.streetmapper.base}>
      <StreetmapperPage />
    </Route>
    <Route exact path={appUrls.settings.streetmapper.create}>
      <CreateStreetmapperPage />
    </Route>
    <Route exact path={appUrls.settings.streetmapper.edit()}>
      <EditStreetmapperPage />
    </Route>
    <Route exact path={appUrls.settings.streetmapper.detail()}>
      <DetailStreetmapperPage />
    </Route>
  </Switch>
);
