import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';

export const selectAllIds = (store: IRootState) => store.settings.streetmapper.list.allIds;
export const selectById = (store: IRootState) => store.settings.streetmapper.list.byId;
export const selectStreetMappersTable = (store: IRootState) => store.settings.streetmapper.list.table;
export const selectLoading = (store: IRootState) => store.settings.streetmapper.list.loading;

export const getStreetmappersList = createAllEntitiesSelector(selectAllIds, selectById);

export const getPagedApiParams = createPagedApiParamsSelector(selectStreetMappersTable);
export const getStreetMapper = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
