import { Actions, TableActions } from '../../common';
import { IStreetmapper, IStreetmapperFilter } from '../../types';

class StreetmapperActionsClass extends Actions {
  list = new TableActions<IStreetmapper, IStreetmapperFilter>(`${this.prefix}.list`);
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<IStreetmapper>('save');
}

export const StreetmapperActions = new StreetmapperActionsClass('streetmapper');
