import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { NewsflashRoutes } from './Newsflash.routes';
import { RouterMenu } from '../molecules';
import { translate } from '../../common/translations/translate';
import { INewsflashAclActions, IStreetmapperAclActions } from '../../types';
import { Auth } from '../atoms';
import { StreetmapperRoutes } from './Streetmapper.routes';
import { useSelector } from 'react-redux';
import { getIsAclUser, selectUserAcl } from '../../common/store/user/user.selectors';

export const SettingsRoutes: FC = () => {
  const userAcl = useSelector(selectUserAcl);
  const isAcl = useSelector(getIsAclUser);

  return (
    <Auth acl={[INewsflashAclActions.manageNewsflashes, IStreetmapperAclActions.viewStreetMapper]}>
      <RouterMenu
        tabs={[
          {
            label: translate('settings.newsflashes.title'),
            route: appUrls.settings.newsflashes.base,
            visible: !isAcl || !!userAcl?.[INewsflashAclActions.manageNewsflashes],
          },
          {
            label: translate('settings.streetmapper.title'),
            route: appUrls.settings.streetmapper.base,
            visible: !isAcl || !!userAcl?.[IStreetmapperAclActions.viewStreetMapper],
          },
        ]}
      />
      <Switch>
        <Route path={appUrls.settings.newsflashes.base}>
          <NewsflashRoutes />
        </Route>
        <Route path={appUrls.settings.streetmapper.base}>
          <StreetmapperRoutes />
        </Route>
      </Switch>
    </Auth>
  );
};
