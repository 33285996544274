import AddIcon from '@material-ui/icons/Add';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Container, FormattedDateTime, IPaging, ISorting } from '../../../../../common';
import { Button } from '../../../../../common/components/buttons/asign-button-extensions';
import { GenericTablePanel } from '../../../../../common/components/table/GenericTablePanel.component';
import { translate } from '../../../../../common/translations/translate';
import { useGeneralStyles } from '../../../../../style/generalStyles';
import { IStreetmapper, IStreetmapperAclActions } from '../../../../../types';
import { Auth } from '../../../../atoms';
import { useFormStyles } from '../../../../../style/form.styles';
import { StreetmapperActions } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { appUrls } from '../../../../../common/config/url.constants';
import { useStreetMapper } from '../../../../../hooks/useStreetMapper.hook';

const useStyles = createUseStyles({
  table: {
    marginTop: 15,
  },
  id: {
    width: '5%',
    overflow: 'auto',
  },
});

export const StreetmapperPage: FC = () => {
  const C = useStyles();
  const M = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    streetmappers,
    table: { sorting, paging },
    loading,
  } = useStreetMapper();

  const onClick = ({ id }: IStreetmapper) => history.push(appUrls.settings.streetmapper.detail(`${id}`));

  const onChangeSorting = (sorting: ISorting) => dispatch(StreetmapperActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(StreetmapperActions.list.fetch({ paging }));

  return (
    <Container>
      <Auth acl={IStreetmapperAclActions.editStreetMapper}>
        <Button.Green
          className={M.createButton}
          startIcon={<AddIcon fontSize={'small'} />}
          onClick={() => history.push(appUrls.settings.streetmapper.create)}
        >
          {translate('settings.streetmapper.newStreetmapper')}
        </Button.Green>
      </Auth>
      <Auth acl={IStreetmapperAclActions.viewStreetMapper}>
        <GenericTablePanel
          dataTestId="SgwProjectsListTable"
          className={C.table}
          labelUnavailable={translate('settings.streetmapper.list.unavailable')}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          loading={loading}
          records={streetmappers}
          title={<div className={G.flexRowSpaceBetween}>{translate('settings.streetmapper.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('settings.streetmapper.columns.id'),
              name: 'id',
              sortable: true,
              className: C.id,
            },
            {
              label: translate('settings.streetmapper.columns.streetName'),
              name: 'streetName',
              sortable: true,
            },
            {
              label: translate('settings.streetmapper.columns.zipCode'),
              name: 'zipCode',
              sortable: true,
            },
            {
              label: translate('settings.streetmapper.columns.createdAt'),
              name: 'createdAt',
              renderer: (createdAt: string) => <FormattedDateTime dateTime={createdAt} />,
              sortable: true,
            },
            {
              label: translate('settings.streetmapper.columns.updatedAt'),
              name: 'updatedAt',
              renderer: (updatedAt: string) => <FormattedDateTime dateTime={updatedAt} />,
              sortable: true,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
