import { useForm } from 'react-hook-form';
import { IRouterWithId, IStreetmapper } from '../../types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StreetmapperActions } from '../../store/actions';
import { useParams } from 'react-router';
import { getStreetMapper } from '../../store/selectors/streetmapper.selectors';

export const useStreetmapperForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getStreetMapper(id));

  const formMethods = useForm<IStreetmapper>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = useCallback(
    (values: IStreetmapper) => {
      dispatch(StreetmapperActions.save(values));
    },
    [dispatch],
  );

  const submitStreetmapper = handleSubmit(_onSubmit);

  return { formMethods, submitStreetmapper, id };
};
