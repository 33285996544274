import { Grid, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, PanelAction } from '../../../../../common';
import { Header } from '../../../../../common/components/layout/Header.component';
import { PanelContent } from '../../../../../common/components/panel/panel-content.component';
import { appUrls } from '../../../../../common/config/url.constants';
import { translate } from '../../../../../common/translations/translate';
import { DateFormat, IRouterWithId, IStreetmapperAclActions } from '../../../../../types';
import { Auth } from '../../../../atoms';
import { useFormStyles } from '../../../../../style/form.styles';
import { StreetmapperActions } from '../../../../../store/actions';
import { Loader } from '../../../../../common/components/layout/loader.component';
import { LabelValue } from '../../../../../common/components/layout/LabelValue.component';
import moment from 'moment';
import { getStreetMapper } from '../../../../../store/selectors/streetmapper.selectors';

export const DetailStreetmapperPage: FC = () => {
  const C = useFormStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const streetmapper = useSelector(getStreetMapper(id));
  const history = useHistory();

  useEffect(() => {
    dispatch(StreetmapperActions.fetch(id));
  }, [dispatch, id]);

  const onClick = () => history.push(appUrls.settings.streetmapper.edit(id));

  return streetmapper ? (
    <Auth acl={IStreetmapperAclActions.viewStreetMapper} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.settings.streetmapper.base}>
        {translate('settings.streetmapper.detailTitle', { streetname: streetmapper.streetName })}
      </Header>
      <Container className={C.container} data-testid="permitConditionInfo">
        <Paper square>
          <PanelContent title={translate('settings.streetmapper.details')}>
            <Auth acl={IStreetmapperAclActions.editStreetMapper}>
              <PanelAction icon={<Edit />} onClick={onClick} data-testid="editButton" />
            </Auth>
            <Grid container spacing={2} className={C.grid}>
              <LabelValue label={translate('settings.streetmapper.columns.id')}>{streetmapper.id}</LabelValue>
              <LabelValue label={translate('settings.streetmapper.columns.streetName')}>
                {streetmapper.streetName}
              </LabelValue>
              <LabelValue label={translate('settings.streetmapper.columns.zipCode')}>{streetmapper.zipCode}</LabelValue>
              <LabelValue label={translate('settings.streetmapper.columns.createdAt')}>
                {moment(streetmapper.createdAt).format(DateFormat.date)}
              </LabelValue>
              <LabelValue label={translate('settings.streetmapper.columns.updatedAt')}>
                {moment(streetmapper.updatedAt).format(DateFormat.date)}
              </LabelValue>
            </Grid>
          </PanelContent>
        </Paper>
      </Container>
    </Auth>
  ) : (
    <Loader />
  );
};
