import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Panel, PanelAction } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SubmitButton } from '../../atoms';
import { useNewsflashForm } from '../../../hooks/forms/useNewsflashForm.hook';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import moment from 'moment/moment';
import { DateFormat, NewsflashLevel, NewsflashType } from '../../../types';
import { convertDateFormat } from '../../../common/utils/date.util';
import { NewsflashActions, StreetmapperActions } from '../../../store/actions';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { useStreetmapperForm } from '../../../hooks/forms/useStreetmapperForm.hook';

interface IProps {
  title: string;
}

export const StreetmapperForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitStreetmapper, id } = useStreetmapperForm();
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = formMethods;

  useEffect(() => {
    id && dispatch(StreetmapperActions.fetch(id));
  }, [dispatch, id]);

  const goToDetail = () => history.push(appUrls.settings.streetmapper.detail(id));

  return (
    <FormProvider {...formMethods}>
      <form id="createStreetmapper" onSubmit={submitStreetmapper} noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('streetName', { required: true })}
                label={translate('settings.streetmapper.columns.streetName')}
                className={G.fullWidth}
                required
                error={!!errors.streetName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('zipCode', { required: true })}
                label={translate('settings.streetmapper.columns.zipCode')}
                className={G.fullWidth}
                required
                error={!!errors.zipCode}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createStreetmapper">{translate('settings.streetmapper.save')}</SubmitButton>
      </form>
    </FormProvider>
  );
};
