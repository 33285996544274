import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getStreetmappersList,
  selectLoading,
  selectStreetMappersTable,
} from '../store/selectors/streetmapper.selectors';
import { StreetmapperActions } from '../store/actions';

export const useStreetMapper = (fetchAll = false) => {
  const streetmappers = useSelector(getStreetmappersList);
  const table = useSelector(selectStreetMappersTable);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(StreetmapperActions.list.fetch({}));
  }, [dispatch, fetchAll]);

  return { streetmappers, table, loading };
};
